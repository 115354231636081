.checkList {
  margin: 0;
  padding: 0;
  list-style-type: none;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  gap: 1.25em;
  @include media-breakpoint-up(sm) {
    font-size: 18px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 21px;
  }
  li {
    display: flex;
    gap: 10px;
    align-items: flex-start;
    justify-content: flex-start;
    &::before {
      font-family: "Font Awesome 5 Pro";
      content: "\f00c";
      font-weight: normal;
    }
  }
}
