.toggleMenu {
  background-color: $body-color;
  color: $white;
  z-index: $zindex-fixed;
  position: fixed;
  top: 0;
  right: 0;
  width: 300px;
  height: 101vh;
  .header {
    display: flex;
    justify-content: flex-end;
    border-bottom: 1px solid tint-color($body-color, 10%);
    .toggleMenuBtn {
      height: 70px;
      width: 70px;
      border-left: 1px solid tint-color($body-color, 10%);
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }
  }
  nav {
    .gnav {
      margin: 0;
      padding: 0;
      list-style-type: none;
      font-size: 12px;
      li {
        border-bottom: 1px solid tint-color($body-color, 10%);
        a {
          color: $white;
          text-decoration: none;
          display: block;
          padding: 1rem;
          &:hover {
            color: $white;
          }
        }
      }
    }
    .unav {
      margin: 0;
      padding: 0;
      list-style-type: none;
      font-size: 10px;
      li {
        border-bottom: 1px solid tint-color($body-color, 10%);
        a {
          color: $white;
          text-decoration: none;
          display: block;
          padding: 1rem;
          &:hover {
            color: $white;
          }
        }
      }
    }
    .inav {
      margin: 0;
      padding: 0;
      list-style-type: none;
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      border-bottom: 1px solid tint-color($body-color, 10%);
      li {
        border-left: 1px solid tint-color($body-color, 10%);
        flex: 1;
        &:first-child {
          border-left: none;
        }
        a {
          color: $white;
          text-decoration: none;
          display: flex;
          padding: 1.5rem;
          align-items: center;
          justify-content: center;
          &:hover {
            color: $white;
          }
        }
      }
    }
  }
}
