.flow {
  .flowSingle {
    display: grid;
    grid-template:
      "number ... title" 50px
      "...... ... ....." 15px
      "...... ... cont"
      / 50px 15px 1fr;
    margin-bottom: 60px;
    background-color: $white;
    padding: 30px;
    @include media-breakpoint-up(sm) {
      grid-template:
        "number ... title" 60px
        "...... ... cont"
        / 60px 15px 1fr;
    }
    @include media-breakpoint-up(lg) {
      grid-template:
        "number ... title" 90px
        "...... ... cont"
        / 90px 30px 1fr;
      margin-bottom: 90px;
      padding: 45px;
    }
    &:last-child {
      margin-bottom: 0;
    }
    .number {
      grid-area: number;
      width: 100%;
      height: 100%;
      background-color: $body-color;
      color: $white;
      font-size: 18px;
      font-weight: bold;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 0;
      @include media-breakpoint-up(sm) {
        font-size: 21px;
      }
      @include media-breakpoint-up(lg) {
        font-size: 27px;
      }
    }
    .title {
      grid-area: title;
      width: 100%;
      height: 100%;
      font-size: 18px;
      font-weight: bold;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 0;
      @include media-breakpoint-up(sm) {
        font-size: 21px;
      }
      @include media-breakpoint-up(lg) {
        font-size: 27px;
      }
    }
    .cont {
      grid-area: cont;
      font-size: 14px;
      @include media-breakpoint-up(sm) {
        font-size: 15px;
      }
    }
  }
}
