.sectionHeader {
  position: relative;
  margin-bottom: 36px;
  @include media-breakpoint-up(sm) {
    margin-bottom: 45px;
  }
  &::before {
    position: absolute;
    top: 18px;
    left: -6vw;
    width: calc(6vw - 10px);
    height: 2px;
    background-color: $body-color;
    content: "";
    @include media-breakpoint-up(sm) {
      top: 27px;
      width: calc(6vw - 20px);
    }
    @include media-breakpoint-up(lg) {
      width: calc(6vw - 30px);
    }
  }
  .title {
    font-size: 30px;
    line-height: 1.25;
    margin: 0;
    @include media-breakpoint-up(sm) {
      font-size: 45px;
    }
  }
}
