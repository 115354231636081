.widelayout {
  padding: 60px 0;
  @include media-breakpoint-up(sm) {
    padding: 90px 0;
  }
  @include media-breakpoint-up(lg) {
    padding: 120px 0;
  }
  &.topWhite {
    width: 100%;
    position: relative;
    &::before {
      width: 100%;
      height: 180px;
      @include media-breakpoint-up(sm) {
        height: 270px;
      }
      @include media-breakpoint-up(lg) {
        height: 360px;
      }
      content: "";
      background-color: $white;
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
    }
    .brandContainer {
      position: relative;
      z-index: 2;
    }
  }
}
.bg-inversion {
  background-color: $body-color;
  color: $body-bg;
}
.brandContainer {
  padding: 0 6vw;
}
.brandSpace {
  height: 60px;
  @include media-breakpoint-up(sm) {
    height: 90px;
  }
  @include media-breakpoint-up(lg) {
    height: 120px;
  }
}
.contentsContainer {
  max-width: calc(800px + 12vw);
  padding: 0 6vw;
  margin: 0 auto;
}
.magazineCont {
  max-width: 1200px;
  margin: 0 auto;
  .sideCont {
    margin-top: 60px;
    @include media-breakpoint-up(sm) {
      margin-top: 90px;
    }
    @include media-breakpoint-up(lg) {
      margin-top: 0;
    }
  }
  @include media-breakpoint-up(lg) {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    .mainCont {
      width: 800px;
    }
    .sideCont {
      width: 320px;
    }
  }
}
