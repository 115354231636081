.questionary {
  margin: 0;
  padding: 1.5rem;
  background-color: rgba($color: #000000, $alpha: 0.2);
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: 10px;
  li {
    display: flex;
    gap: 10px;
    align-items: flex-start;
    justify-content: flex-start;
    &::before {
      font-family: "Font Awesome 5 Pro";
      content: "\f0c8";
    }
  }
}
