.voiceCard {
  background-color: $bg-gold;
  border-left: 2px solid $gold;
  color: $body-color;
  padding: 2rem;
  height: 100%;
  .voice {
    font-size: 15px;
    line-height: 2;
    font-weight: bold;
    margin: 0;
  }
  .name {
    font-size: 12px;
    margin: 0;
    margin-top: 2rem;
  }
}
