.worksImages {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  .worksImagesSingle {
    flex-basis: 50%;
    padding: 0 5px 10px;
    &:first-child {
      flex-basis: 100%;
    }
    a {
      display: block;
      position: relative;
      width: 100%;
      overflow: hidden;
      border: 1px solid $border-color;
      // box-shadow: $box-shadow-lg;
      background-color: $white;
      &::before {
        content: "";
        width: 100%;
        padding-top: calc(1 / 1.618 * 100%); //高さの比率 ÷ 幅の比率 × 100
        display: block;
      }
      img {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: auto;
      }
      &.second {
        &::before {
          padding-top: calc(1 / 0.5 * 100%);
        }
      }
    }
  }
}
