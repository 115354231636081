.zoomupAnimation[data-trigger] {
  transform: scale(0);
  opacity: 0;
  transform-origin: center center;
  transition: $transition-base;
  &.InView {
    opacity: 1;
    transform: scale(1);
  }
}
.leftAnimation[data-trigger] {
  transform: translateX(10px);
  opacity: 0;
  transition: $transition-base;
  &.InView {
    transform: translateX(0);
    opacity: 1;
  }
}
.rightAnimation[data-trigger] {
  transform: translateX(-10px);
  opacity: 0;
  transition: $transition-base;
  &.InView {
    transform: translateX(0);
    opacity: 1;
  }
}
.topAnimation[data-trigger] {
  transform: translateY(10px);
  opacity: 0;
  transition: $transition-base;
  &.InView {
    transform: translateY(0);
    opacity: 1;
  }
}
/* アニメーション中のスタイル */
.fadeInOut-leave-active,
.fadeInOut-enter-active {
  transition: $transition-base;
}

/* 表示アニメーション */
.fadeInOut-enter {
  opacity: 0;
}
.fadeInOut-enter-to {
  opacity: 1;
}

/* 非表示アニメーション */
.fadeInOut-leave {
  opacity: 1;
}
.fadeInOut-leave-to {
  opacity: 0;
}
