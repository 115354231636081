.hero {
  width: 100%;
  height: calc(100vh - 100px);
  padding: 60px 6vw;
  background-color: $gray-100;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  background-color: $body-color;
  color: $white;
  @supports (-webkit-touch-callout: none) {
    height: -webkit-fill-available;
  }
  .bg {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .cont {
    position: relative;
    z-index: 2;
    .catch {
      font-size: 21px;
      line-height: 1.5;
      @include media-breakpoint-up(sm) {
        font-size: 36px;
      }
    }
  }
}
