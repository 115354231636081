.catchCopy {
  font-size: 15px;
  font-weight: bold;
  line-height: 2;
  margin-bottom: 45px;
  @include media-breakpoint-up(sm) {
    font-size: 18px;
    margin-bottom: 60px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 21px;
  }
}
