.midashi1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
  margin-bottom: 45px;
  @include media-breakpoint-up(sm) {
    margin-bottom: 60px;
  }
  &::before {
    content: "";
    width: 45px;
    height: 2px;
    background-color: $gold;
    @include media-breakpoint-up(sm) {
      width: 60px;
      margin-bottom: 10px;
    }
  }
}
