.secondHeader {
  padding: 60px 6vw;
  background-color: $gray-100;
  border-bottom: 1px solid $border-color;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  background-color: $body-color;
  color: $white;
  position: relative;
  @include media-breakpoint-up(sm) {
    padding: 90px 6vw;
    flex-direction: row;
    align-items: center;
  }
  @include media-breakpoint-up(lg) {
    padding: 120px 6vw;
  }
  .bg {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .cont {
    position: relative;
    z-index: 2;
    .catch {
      font-size: 24px;
      line-height: 1.5;
      margin: 0;
      @include media-breakpoint-up(sm) {
        font-size: 36px;
      }
    }
    .subtitle {
      font-size: 12px;
      font-weight: bold;
      margin: 0;
      margin-top: 15px;
      @include media-breakpoint-up(sm) {
        font-size: 15px;
      }
    }
  }
  .cta {
    margin-top: 15px;
    position: relative;
    z-index: 2;
    @include media-breakpoint-up(sm) {
      margin-top: 0;
      margin-right: 15px;
    }
  }
}
