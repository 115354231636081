.lNav {
  width: 100%;
  padding: 30px 6vw;
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
    margin-left: -5px;
    margin-right: -5px;
    li {
      padding: 5px;
      width: calc(100% / 3);
      @include media-breakpoint-up(sm) {
        width: calc(100% / 5);
      }
      @include media-breakpoint-up(lg) {
        width: calc(100% / 6);
      }
      a {
        display: block;
        color: $body-color;
        text-decoration: none;
        text-align: center;
        padding: 15px 10px;
        background-color: $gray-100;
        border: 1px solid $border-color;
        width: 100%;
        height: 100%;
        .icon {
          font-size: 30px;
        }
        .text {
          margin-top: 5px;
          font-size: 12px;
          line-height: 1.2;
        }
      }
    }
  }
}
