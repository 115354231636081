.brandTable {
  border-top: 1px solid $border-color;
  @include media-breakpoint-up(sm) {
    display: table;
    width: 100%;
  }
  dl {
    margin: 0;
    padding: 0;
    @include media-breakpoint-up(sm) {
      display: table-row;
    }
    dt {
      margin: 0;
      padding: 1.5rem 0 0;
      @include media-breakpoint-up(sm) {
        display: table-cell;
        width: 1%;
        white-space: nowrap;
        padding: 1.5rem 0;
        border-bottom: 1px solid $border-color;
        vertical-align: top;
      }
    }
    dd {
      margin: 0;
      padding: 1rem 0 1.5rem;
      border-bottom: 1px solid $border-color;
      @include media-breakpoint-up(sm) {
        display: tablecell;
        padding: 1.5rem;
        padding-right: 0;
        vertical-align: top;
      }
    }
  }
}
