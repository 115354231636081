.newsArchive {
  display: grid;
  grid-template:
    "img"
    "..." 20px
    "cont"
    / 100%;
  margin-bottom: 3rem;
  @include media-breakpoint-up(sm) {
    grid-template:
      "img ... cont"
      / 300px 30px 1fr;
  }
  .img {
    grid-area: img;
    position: relative;
    width: 100%;
    overflow: hidden;
    display: block;
    &::before {
      content: "";
      width: 100%;
      padding-top: 56.25%; //高さの比率 ÷ 幅の比率 × 100
      display: block;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: $transition-base;
    }
    &:hover {
      img {
        transform: scale(1.05);
      }
    }
  }
  .cont {
    grid-area: cont;
    .data {
      margin-bottom: 15px;
      display: flex;
      align-items: center;
      font-size: 12px;
      .post-categories {
        display: flex;
        align-items: center;
        list-style-type: none;
        margin: 0;
        padding: 0;
        li {
          margin-right: 1rem;
          a {
            padding: 3px 15px;
            background-color: $white;
            border: 1px solid $border-color;
            color: $secondary;
            text-decoration: none;
            transition: $transition-base;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: $border-radius;
            &:hover {
              color: $white;
              background-color: $secondary;
            }
          }
        }
      }
      .date {
        font-weight: bold;
        margin-right: 1rem;
        color: $secondary;
        font-size: 12px;
      }
    }
    .title {
      font-size: 16px;
      line-height: 1.25;
      font-weight: bold;
      margin-bottom: 15px;
      a {
        color: $body-color;
        text-decoration: none;
        transition: $transition-base;
        &:hover {
          color: $primary;
        }
      }
    }
    .description {
      font-size: 12px;
      color: $secondary;
      margin: 0;
      line-height: 1.75;
    }
  }
}
