// Accordion

// scss-docs-start accordion-variables
$accordion-padding-y: 1.5rem;
$accordion-padding-x: 1.5rem;
$accordion-color: $body-color;
$accordion-bg: $body-bg;
$accordion-border-width: $border-width;
$accordion-border-color: rgba($black, 0.125);
$accordion-border-radius: $border-radius;
$accordion-inner-border-radius: subtract(
  $accordion-border-radius,
  $accordion-border-width
);

$accordion-body-padding-y: $accordion-padding-y;
$accordion-body-padding-x: $accordion-padding-x;

$accordion-button-padding-y: $accordion-padding-y;
$accordion-button-padding-x: $accordion-padding-x;
$accordion-button-color: $accordion-color;
$accordion-button-bg: $accordion-bg;
$accordion-transition: $btn-transition, border-radius 0.15s ease;
$accordion-button-active-bg: $white; //tint-color($component-active-bg, 90%);
$accordion-button-active-color: shade-color($primary, 10%);

$accordion-button-focus-border-color: $input-focus-border-color;
$accordion-button-focus-box-shadow: $btn-focus-box-shadow;

$accordion-icon-width: 1.25rem;
$accordion-icon-color: $accordion-color;
$accordion-icon-active-color: $accordion-button-active-color;
$accordion-icon-transition: transform 0.2s ease-in-out;
$accordion-icon-transform: rotate(-180deg);

$accordion-button-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
$accordion-button-active-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='#{$accordion-icon-active-color}'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
// scss-docs-end accordion-variables
