.headerBar {
  width: 100%;
  height: 30px;
  border-bottom: 1px solid $gray-800;
  padding: 0 15px;
  // display: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $black;
  color: $white;
  @include media-breakpoint-up(sm) {
    padding: 0 30px;
  }
  .catch {
    margin: 0;
    font-size: 10px;
  }
  nav {
    font-size: 10px;
    justify-content: flex-end;
    align-items: center;
    display: none;
    @include media-breakpoint-up(lg) {
      display: flex;
    }
    .inav {
      margin: 0;
      padding: 0;
      list-style-type: none;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      li {
        margin-left: 1.5rem;
        a {
          color: $white;
          text-decoration: none;
          &:hover {
            color: $white;
          }
        }
      }
    }
    .unav {
      margin: 0;
      padding: 0;
      list-style-type: none;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      li {
        margin-left: 1.5rem;
        a {
          color: $white;
          text-decoration: none;
          &:hover {
            color: $white;
          }
        }
      }
    }
  }
}
#header {
  border-bottom: 1px solid $gray-800;
  background-color: $black;
  color: $white;
  width: 100%;
  height: 71px;
  padding-left: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: $zindex-sticky;
  @include media-breakpoint-up(sm) {
    padding-left: 30px;
  }
  .logo {
    img {
      height: 30px;
      width: auto;
    }
  }
  nav {
    font-size: 13px;
    flex: 1;
    padding-right: 30px;
    display: none;
    @include media-breakpoint-up(sm) {
      display: block;
    }
    .gnav {
      margin: 0;
      padding: 0;
      list-style-type: none;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      li {
        margin-left: 1.5rem;
        a {
          color: $white;
          text-decoration: none;
          &:hover {
            color: $white;
          }
        }
      }
    }
  }
  .toggleMenuBtn {
    height: 70px;
    width: 70px;
    border-left: 1px solid $gray-800;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: $black;
    color: $white;
  }
}
