.ctaBlock {
  text-align: center;
  background-color: $body-color;
  color: $white;
  padding: 45px 30px;
  .title {
    font-size: 24px;
    line-height: 1.25;
    margin: 0;
    @include media-breakpoint-up(sm) {
      font-size: 36px;
    }
  }
}
