@import "custom";
@import "~bootstrap/scss/bootstrap";

html {
  font-size: 15px;
  font-feature-settings: "palt";
  letter-spacing: 0.08em;
}
[v-cloak] {
  display: none;
}
img {
  max-width: 100%;
  height: auto;
}

@import "components/header";
@import "components/toggleMenu";
@import "components/hero";
@import "components/lpHero";
@import "components/secondHeader";
@import "components/lNav";
@import "components/layout";
@import "components/voiceCard";
@import "components/sectionHeader";
@import "components/sectionHeader2";
@import "components/catchCopy";
@import "components/midashi1";
@import "components/questionary";
@import "components/checkList";
@import "components/sectionCover";
@import "components/summary";
@import "components/outline";
@import "components/newsArchive";
@import "components/pagination";
@import "components/flow";
@import "components/flow2";
@import "components/openColumn";
@import "components/brandTable";
@import "components/detailTable";
@import "components/worksImages";
@import "components/digest";
@import "components/bulletList";
@import "components/article";
@import "components/coverBgImg";
@import "components/sideMenu";
@import "components/tagCloud";
@import "components/narrowThumbnailLink";
@import "components/imgList";
@import "components/carousels";
@import "components/cta";
@import "components/footer";
@import "components/animation";
