.sectionHeader2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 1rem;
  margin-bottom: 45px;
  @include media-breakpoint-up(sm) {
    margin-bottom: 60px;
  }
  &::before {
    content: "";
    width: 45px;
    height: 2px;
    background-color: $gold;
    @include media-breakpoint-up(sm) {
      width: 60px;
      margin-bottom: 10px;
    }
  }
  .title-en {
    font-size: 36px;
    font-family: $font-family-en;
    text-transform: uppercase;
    margin: 0;
    line-height: 1;
    @include media-breakpoint-up(sm) {
      font-size: 45px;
    }
    @include media-breakpoint-up(lg) {
      font-size: 60px;
    }
  }
  .title-ja {
    font-size: 15px;
    font-weight: bold;
    margin: 0;
    line-height: 1.5;
    letter-spacing: 0.1em;
  }
}
