.detailTable {
  display: table;
  width: 100%;
  border-spacing: 0 10px;
  dl {
    margin: 0;
    padding: 0;
    display: table-row;
    dt {
      margin: 0;
      background-color: tint-color($body-color, 5%);
      color: $body-bg;
      display: table-cell;
      width: 1%;
      white-space: nowrap;
      padding: 1.5rem;
      vertical-align: middle;
      text-align: center;
    }
    dd {
      margin: 0;
      display: tablecell;
      padding: 1.5rem 1rem;
      padding-right: 0;
      vertical-align: top;
    }
  }
}
