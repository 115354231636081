.lpHero {
  background-color: $body-bg;
  @include media-breakpoint-up(sm) {
    width: 100%;
    height: 60vh;
    position: relative;
  }
  @include media-breakpoint-up(lg) {
    height: calc(100vh - 100px);
  }
  .bg {
    display: none;
    @include media-breakpoint-up(sm) {
      display: block;
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 1;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .cont {
    width: 100%;
    .catch {
      margin: 0;
      padding: 0;
      img {
        width: 100%;
      }
    }
    @include media-breakpoint-up(sm) {
      position: absolute;
      z-index: 2;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 0 6vw;
      .catch {
        width: 60%;
        img {
          width: auto;
        }
      }
    }
    @include media-breakpoint-up(lg) {
      .catch {
        width: 50%;
        img {
          max-height: calc(100vh - 220px);
        }
      }
    }
  }
}
