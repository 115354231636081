.sectionCover {
  position: relative;
  img {
    width: 100%;
    height: auto;
    z-index: 1;
  }
  &::after {
    position: absolute;
    bottom: 0;
    left: 3vw;
    width: calc(100% - 6vw);
    height: 60px;
    background-color: $body-color;
    content: "";
    display: none;
    @include media-breakpoint-up(lg) {
      height: 90px;
      display: block;
    }
  }
}
