.bulletList {
  margin: 0;
  padding: 0;
  list-style-type: none;
  font-weight: bold;
  li {
    padding: 5px 0;
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    &::before {
      margin-right: 5px;
      font-family: "Font Awesome 5 Pro";
      content: "\f00c";
      font-weight: normal;
    }
  }
}
